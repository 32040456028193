import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Header from "../components/header"
import TwmButton from "../components/twm-button"
import Section from "../components/section"

const ContactSuccessPage = props => (
  <Layout firmName={props.pageContext.firm} firmWeb={props.pageContext.web}>
    <SEO title="Thank You" />
    <BackgroundImage
      fluid={props.data.contactSuccessImage.childImageSharp.fluid}
      alt="A postbox"
    >
      <Header headerText="We Got Your Message"></Header>
    </BackgroundImage>
    <Section>
      <p>Thank you for getting in touch</p>
      Got to the{" "}
      <TwmButton toRef={props.pageContext.firm + "/"}>home page</TwmButton>
    </Section>
  </Layout>
)

export default ContactSuccessPage

export const pageQuery = graphql`
  query {
    contactSuccessImage: file(relativePath: { eq: "contact-success.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3264) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
